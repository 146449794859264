:root {
  --gradiant-colors: #fa00bb, #f5293a;
  --invert-gradiant-colors: #f5293a, #fa00bb;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.gradient {
  background: linear-gradient(90deg, var(--gradiant-colors));
}
.revert-gradiant {
  background: linear-gradient(90deg, var(--invert-gradiant-colors));
}

.font-chewy {
  font-family: 'Chewy', cursive;
}
